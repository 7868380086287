<template>
    <v-flex xs12 class="padContainer">
        <v-card v-on:click.prevent="item.show = !item.show" style="width: 100%;margin-bottom: 1px;">
            <v-container fluid style="padding: 10px;">
                <v-card-text style="padding: 2px;">
                    <v-layout wrap>

                        <v-flex xs6>
                            <div style="font-size: 1.3em;">{{item.itemName}}</div>
                        </v-flex>
                        <v-flex xs6 style="text-align:right;">
                            <span>{{ item.currency }} {{ total }}</span>
                        </v-flex>
                        <v-flex xs12 style="text-align:right;">
                            <span>Qty {{item.quantity}}</span>
                        </v-flex>

                    </v-layout>
                </v-card-text>
            </v-container>
        </v-card>
    </v-flex>
</template>  

<script>
    import { formatMoney } from '../../javascript/globalFunctions'

    export default {
        props: ["item"],
        data() {
            return {
                total: ''
            }
        },
        beforeMount() {
            this.total = formatMoney(this.item.total, 2, ".", ",")
        }
    }

</script>

<style scoped>

    .flex {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .padContainer {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }
</style>